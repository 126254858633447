var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scrollbar goodsInfo" },
    [
      _c("div", { staticClass: "fs20 padding10 borderBottom" }, [
        _vm._v(" " + _vm._s(_vm.goods.cname) + " "),
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _vm.goods.cmasterUrl
                ? _c(
                    "div",
                    { staticClass: "wHP h350 bcWhite" },
                    [
                      _c("el-image", {
                        staticClass: "border wHP hHP",
                        attrs: { src: _vm.goods.cmasterUrl, fit: "fit" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.urls.length > 0
                ? _c(
                    "el-carousel",
                    {
                      attrs: { interval: 5000, type: "card", height: "150px" },
                      on: { change: _vm.handleChange },
                    },
                    _vm._l(_vm.urls, function (item) {
                      return _c(
                        "el-carousel-item",
                        { key: item },
                        [
                          _c("el-image", {
                            staticClass: "hHP wHP",
                            attrs: { src: item, fit: "fit" },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "fs20 marginBottom10 marginTop10 marginLeft10" },
                [_vm._v("货品标签")]
              ),
              _vm.tags.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.tags, function (tag, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "col-md-3" },
                        [_c("el-tag", [_vm._v(_vm._s(tag.cname))])],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.tags.length === 0
                ? _c("el-empty", {
                    staticClass: "empty",
                    attrs: { description: "暂无数据" },
                  })
                : _vm._e(),
              _c("div", { staticClass: "fs20 marginTop10 marginLeft10" }, [
                _vm._v("相似货品"),
              ]),
              _vm.productData.length === 0
                ? _c("el-empty", {
                    staticClass: "empty",
                    attrs: { description: "暂无数据" },
                  })
                : _vm._e(),
              _vm.productData.length > 0
                ? _c("ProductCard", {
                    attrs: { "product-data": _vm.productData },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "padding10", attrs: { span: 16 } },
            [
              _c("div", { staticClass: "fs20" }, [_vm._v("货品信息")]),
              _c(
                "div",
                { staticClass: "bg-purple-light marginBottom10" },
                [
                  _c(
                    "el-descriptions",
                    {
                      staticClass: "margin-top",
                      attrs: { column: 1, size: _vm.size },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "货品分类" } },
                        [
                          _c("el-cascader", {
                            staticClass: "custom-cas-goods-list color",
                            attrs: {
                              size: "mini",
                              value: _vm.rsort,
                              options: _vm.sortsData,
                              props: { value: "sid", label: "ssort" },
                              separator: "-",
                              placeholder: "暂无数据",
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "货品SKU" } },
                        [_vm._v(_vm._s(_vm.goods.cbarcode))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "货品条码" } },
                        [_vm._v(_vm._s(_vm.goods.csku))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "货品品牌" } },
                        [_vm._v(_vm._s(_vm.goods.cbrand))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "计量单位" } },
                        [_vm._v(_vm._s(_vm.goods.sunit))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "货品型号" } },
                        [_vm._v(_vm._s(_vm.goods.cmodel))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "保质期限" } },
                        [_vm._v(_vm._s(_vm.goods.nperiod))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "货品规格" } },
                        [_vm._v(_vm._s(_vm.goods.cspecification))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "零售价格" } },
                        [_vm._v(_vm._s(_vm.goods.nprice) + " 元")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "marginBottom10" }, [
                _c("span", { staticClass: "fs20" }, [_vm._v("库存分布")]),
                _c("span", { staticClass: "fs13" }, [
                  _vm._v(" 显示当前区域所有门店的库存分布"),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticClass: "wHP marginBottom10",
                  attrs: { data: _vm.tableData, height: "300", border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "日期", width: "180" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "姓名", width: "180" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "地址" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "fs20 marginBottom10" }, [
                _vm._v("货品详情"),
              ]),
              _c("span", { staticClass: "fs15" }, [
                _vm._v(_vm._s(_vm.goods.cdescribe)),
              ]),
            ],
            1
          ),
          _c("el-col", [
            _c(
              "div",
              {
                staticClass:
                  "bcWhite borderTop borderRadius5 marginTop10 padding10",
              },
              [
                _c(
                  "el-row",
                  { attrs: { type: "flex", justify: "end" } },
                  [
                    _c("el-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取消"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }