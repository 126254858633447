<template>
  <!-- 货品信息-->
  <div class="scrollbar goodsInfo">
    <div class="fs20 padding10 borderBottom">
      {{ goods.cname }}
    </div>
    <el-row>
      <el-col :span="8">
        <!--        <div v-if="goods.cmasterUrl === undefined" class="wHP h350">-->
        <!--          <el-image :key="url" :src="url" class="h350 w350" style="margin-left: auto; margin-right: auto; display: block" />-->
        <!--        </div>-->
        <div v-if="goods.cmasterUrl" class="wHP h350 bcWhite">
          <el-image class="border wHP hHP" :src="goods.cmasterUrl" fit="fit" />
        </div>
        <el-carousel v-if="urls.length > 0" :interval="5000" type="card" height="150px" @change="handleChange">
          <el-carousel-item v-for="item in urls" :key="item">
            <el-image :src="item" fit="fit" class="hHP wHP" />
          </el-carousel-item>
        </el-carousel>
        <div class="fs20 marginBottom10 marginTop10 marginLeft10">货品标签</div>
        <div v-if="tags.length > 0">
          <div v-for="(tag, index) in tags" :key="index" class="col-md-3">
            <el-tag>{{ tag.cname }}</el-tag>
          </div>
        </div>
        <el-empty v-if="tags.length === 0" class="empty" description="暂无数据"></el-empty>
        <div class="fs20 marginTop10 marginLeft10">相似货品</div>
        <el-empty v-if="productData.length === 0" class="empty" description="暂无数据"></el-empty>
        <ProductCard v-if="productData.length > 0" :product-data="productData" />
      </el-col>
      <el-col :span="16" class="padding10">
        <div class="fs20">货品信息</div>
        <div class="bg-purple-light marginBottom10">
          <el-descriptions class="margin-top" :column="1" :size="size">
            <el-descriptions-item label="货品分类"><el-cascader size="mini" :value="rsort" :options="sortsData" :props="{ value: 'sid', label: 'ssort' }" separator="-" class="custom-cas-goods-list color" placeholder="暂无数据" disabled></el-cascader></el-descriptions-item>
            <!-- <el-descriptions-item label="货品SKU">{{ goods.csku }}</el-descriptions-item> -->
            <el-descriptions-item label="货品SKU">{{ goods.cbarcode }}</el-descriptions-item>
            <!-- <el-descriptions-item label="货品条码">{{ goods.cbarcode }}</el-descriptions-item> -->
             <el-descriptions-item label="货品条码">{{ goods.csku }}</el-descriptions-item>
            <el-descriptions-item label="货品品牌">{{ goods.cbrand }}</el-descriptions-item>
            <el-descriptions-item label="计量单位">{{ goods.sunit }}</el-descriptions-item>
            <el-descriptions-item label="货品型号">{{ goods.cmodel }}</el-descriptions-item>
            <el-descriptions-item label="保质期限">{{ goods.nperiod }}</el-descriptions-item>
            <el-descriptions-item label="货品规格">{{ goods.cspecification }}</el-descriptions-item>
            <el-descriptions-item label="零售价格">{{ goods.nprice }} 元</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="marginBottom10">
          <span class="fs20">库存分布</span>
          <span class="fs13"> 显示当前区域所有门店的库存分布</span>
        </div>
        <el-table :data="tableData" height="300" border class="wHP marginBottom10">
          <el-table-column prop="date" label="日期" width="180"></el-table-column>
          <el-table-column prop="name" label="姓名" width="180"></el-table-column>
          <el-table-column prop="address" label="地址"></el-table-column>
        </el-table>
        <div class="fs20 marginBottom10">货品详情</div>
        <span class="fs15">{{ goods.cdescribe }}</span>
        <!--        <el-image v-for="url in urls" :key="url" :src="url" lazy></el-image>-->
      </el-col>
      <el-col>
        <div class="bcWhite borderTop borderRadius5 marginTop10 padding10">
          <el-row type="flex" justify="end">
            <el-button @click="cancel">取消</el-button>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ProductCard from "./goodsCard.vue";
import { getGoodsBySkuId, getFileUrls, getGoodsById, getLabelsById, getSimilarGoodsById, goodsSortList, handleTree } from "../../api/goods/goods";

export default {
  name: "GoodsInfo",
  components: { ProductCard },
  data() {
    return {
      url: require("@/assets/noImage.png"),
      sid: this.$route.query.sid,
      rsort: this.$route.query.rsort,
      sortsData: this.$route.query.sortsData,
      size: "",
      fits: ["fill", "contain", "cover", "none", "scale-down"],
      tableData: [{ date: "2016-05-03", name: "123", address: "上海市普陀区金沙江路 1518 弄" }],
      urls: [],
      tags: [],
      productData: [],
      goods: {
        cname: "", //货品名称
        rsort: "", //货品分类
        csku: "", //货品SKU
        ccode: "", //货品编号
        cbrand: "", //货品品牌
        sunit: "", //货品单位
        cmodel: "", //货品型号
        nperiod: "", //保质期限
        cspecification: "", //货品规格
        nprice: 0, //货品单价
        cmasterUrl: "",
        cdescribe: "",
      },
      sortsListData: [],
      sortsTreeData: [],
    };
  },
  mounted() {
    //货品信息
    this.fetchGoods(this.sid);
    //近似货品列表
    this.fetchSimilarGoods(this.sid);
    //货品标签
    this.fetchTags(this.sid);
  },
  created() {
    this.fetchGoodSorts();
    this.getFileUrls();
  },
  methods: {
    handleChange(value) {
      this.goods.cmasterUrl = this.urls[value];
    },
    fetchGoods(sid) {
      getGoodsById(sid).then((response) => {
        if (response.data != null || response.data !== undefined) {
          this.goods = response.data;
        } else {
          getGoodsBySkuId(sid).then((response) => {
            this.goods = response.data;
          });
        }
      });
    },
    fetchSimilarGoods(sid) {
      getSimilarGoodsById(sid).then((response) => {
        if (response.data != null || response.data !== undefined) {
          this.productData = response.data;
        }
      });
    },
    fetchTags(sid) {
      getLabelsById(sid).then((response) => {
        this.tags = response.data;
      });
    },
    //查询分类数据
    fetchGoodSorts() {
      goodsSortList().then((response) => {
        this.sortsListData = response.data;
        this.sortsTreeData = handleTree(response.data, "sid", "rsort");
      });
    },
    handleCascaderChange(value) {
      this.goods.rsort = value[value.length - 1];
    },
    //加载单据已上传图片，初始this.urls
    getFileUrls() {
      getFileUrls(this.sid).then((response) => {
        this.urls = response.data.map((item) => item.curl);
      });
    },
    cancel() {
      // this.$router.push({ path: "/goods/index" });
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.goodsInfo {
  height: 100%;
  &__left {
    width: 500px;
  }
  // &__right {
  // }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    padding-left: 10px;
  }
  .el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
    padding-top: 5px;
    border-bottom: 1px solid rgb(222, 222, 222);
  }
  .el-descriptions-item__label:not(.is-bordered-label) {
    color: #86909c;
  }
}
</style>
